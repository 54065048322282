<template>
  <div
    class="tariff-small-card"
    :class="{ 'tariff-small-card--active': isNextTariff }"
    @click="
      $router.push({
        name: 'TariffsDetailed',
        params: { tariffId: id },
      })
    "
  >
    <div class="tariff-small-card__top">
      <div>
        <p v-if="statusLabel" class="tariff-small-card__status" :class="statusClass">
          {{ statusLabel }}
        </p>
        <p class="tariff-small-card__tariff-name">{{ $t(name) }}</p>
      </div>

      <div
        class="tariff-small-card__arrow"
        :class="{ 'tariff-small-card__arrow--with-shorten': isEn }"
      >
        <p>{{ $t('label.more') }}</p>
        <svg
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 13L7 7L1 1" stroke="#F2F2F2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
    <div class="tariff-small-card__bottom">
      <p class="tariff-small-card__students-info">
        <span>{{ studentCount }} </span> {{ $t('label.students') }}
      </p>
      <p class="tariff-small-card__amount">
        <span>{{ price.toLocaleString() }} </span> {{ $t('label.payInMonth') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TariffSmallCard',
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    studentCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({ userTariff: 'auth/getUserTariff' }),
    isTariffActive() {
      return this.userTariff.name === this.name;
    },
    isEn() {
      return this.$i18n?.locale === 'en';
    },
    isNextTariff() {
      return (
        this.userTariff.prolongation.name === this.name
        && this.userTariff.prolongation.id !== this.userTariff.membership.id
      );
    },
    statusLabel() {
      if (this.isTariffActive) {
        return this.$t('label.connected');
      }
      if (this.isNextTariff) {
        return this.$t('label.next2');
      }
      return false;
    },
    statusClass() {
      if (this.isTariffActive) {
        return 'tariff-small-card__status--color-green';
      }
      if (this.isNextTariff) {
        return 'tariff-small-card__status--color-primary';
      }
      return '';
    },
  },
};
</script>
