<template>
  <div class="manage-tariffs container p-0">
    <div class="manage-tariffs__wrapper">
      <p class="manage-tariffs__title">{{ $t('label.subscriptionManagement') }}</p>

      <div class="manage-tariffs__tariffs tariffs">
        <div class="tariffs__user-tariff">
          <UserCurrentTariffInfo
            :showPaymentHistory.sync="paymentHistory.showHistory"
            :lastPaymentInfo="lastPaymentInfo"
            :forgotCard="forgotCard"
            :isCardData="isCardData"
          />
        </div>
        <div class="tariffs__tariffs-info">
          <TariffsInfoCompact :tariffs="tariffs" :isCardData="isCardData"/>
        </div>
      </div>
      <div class="manage-tariffs__action action">
        <div class="action__button">
          <AddButton
            :variant="buttonVariant"
            :title="buttonTitle"
            :isDisabled="isDisableButtonAutoProlongation"
            @click="handleAutopay"
          />
        </div>
        <div class="action__info">
          <p v-if="isTariffFree">{{ $t('label.toAddStudentToProgramSelectTariff') }}</p>
          <div class="footnote" v-else>
            <AsteriskIcon />
            <p>{{ `${$t('supportText.switchToFreeTariffOnAutoPaymentCancel')}` }}</p>
          </div>
        </div>
      </div>

      <div class="manage-tariffs__history">
        <div>
          <div>{{ $t('label.paymentHistory') }}</div>
          <div @click="paymentHistory.showHistory = true">{{ $t('label.showAll') }}</div>
        </div>
        <div class="lms-card">
          <TablePaymentHistory :items="shortHistory" />
        </div>
      </div>
    </div>

    <PaymentHistory
      class="manage-tariffs__payment-history"
      :class="{ 'slide-up': paymentHistory.showHistory }"
      :showPaymentHistory.sync="paymentHistory.showHistory"
      :items="paymentHistory.items"
      :total="paymentHistory.total"
      @showMore="showMore"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddButton from '@/components/Buttons/AddButton.vue';
import SubscriptionService from '@/services/subscription.service';
import UserCurrentTariffInfo from '@/components/Cards/UserCurrentTariffInfo.vue';
import TariffsInfoCompact from '@/components/Cards/TariffsInfoCompact.vue';
import AuthService from '@/services/auth.service';
import AsteriskIcon from '@/components/Icons/AsteriskIcon.vue';
import PaymentHistory from '@/components/PaymentHistory.vue';
import { tariffPlans } from '@/utils/tariff';
import TablePaymentHistory from '@/components/TablePaymentHistory.vue';

export default {
  name: 'ManageTariffs',
  components: {
    TablePaymentHistory,
    PaymentHistory,
    TariffsInfoCompact,
    UserCurrentTariffInfo,
    AddButton,
    AsteriskIcon,
  },
  data: () => ({
    loading: false,
    paymentResponse: null,
    lastPaymentInfo: null,

    paymentHistory: {
      loading: false,
      showHistory: false,
      items: [],
      total: 0,
      perPage: 10,
    },
  }),
  async created() {
    await AuthService.whoAmI();
    this.lastPaymentInfo = await SubscriptionService.getCardData();
    this.getPaymentHistory();
  },
  methods: {
    ...mapActions('toaster', ['setToaster']),
    forgotCard() {
      SubscriptionService.forgotCardData()
        .then(() => {
          this.lastPaymentInfo = null;
          AuthService.whoAmI();
          return this.setToaster({
            type: 'toast-success',
            toast: {
              title: this.$t('label.paymentInformationRemoved'),
              body: this.$t('supportText.addPaymentInformation'),
            },
          });
        })
        .catch(() => {
          const toast = {
            title: this.$t('errorMessages.somethingWentWrong'),
            body: this.$t('errorMessages.tryAgain'),
          };
          this.setToaster({
            type: 'toast-danger',
            toast,
          });
        });
    },
    abortSubscription(isConfirmed) {
      if (!isConfirmed) {
        return;
      }
      this.loading = true;
      SubscriptionService.disableSubscription()
        .then(async () => {
          // refresh user info
          await AuthService.whoAmI();

          this.setToaster({
            type: 'toast-success',
            toast: {
              title: this.$t('label.autoPaymentCanceled'),
              body: `${this.$t('supportText.yourTariffWorksUntil')} ${
                this.userTariff.expiresAt
              }, ${this.$t('supportText.freeTariffWillBeActivated')} ${this.$t(
                this.userTariff.prolongation.name,
              )}`,
            },
          });
        })
        .catch(this.errorMessage)
        .finally(() => (this.loading = false));
    },
    enableSubscription() {
      this.loading = true;
      SubscriptionService.enableSubscription()
        .then(AuthService.whoAmI)
        .catch(this.errorMessage)
        .finally(() => (this.loading = false));
    },
    openConfirmationUrl({ data }) {
      this.paymentResponse = data;
      if (data?.confirmation?.confirmation_url) {
        window.open(data.confirmation.confirmation_url, 'true');
      } else {
        this.paymentResponse = { ...data, failed_to_get_confirmation_url: true };
      }
    },
    handleAutopay() {
      if (this.isDisableButtonAutoProlongation) {
        return;
      }

      if (this.canBeProlongated) {
        return this.enableSubscription();
      }

      this.$bvModal
        .msgBoxConfirm(this.$t('supportText.switchToFreeTariffOnAutoPaymentCancel'), {
          size: 'md',
          okVariant: 'secondary',
          cancelVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'lms-modal__footer border-top-0',
          centered: true,
          bodyClass: 'lms-modal__body',
          okTitle: this.$t('buttonLabels.cancelAutoPayment'),
          cancelTitle: this.$t('buttonLabels.back'),
        })
        .then(this.abortSubscription)
        .catch(this.errorMessage);
    },
    getPaymentHistory() {
      this.paymentHistory.loading = true;
      return SubscriptionService.getPaymentHistory({
        page: 1,
        perPage: this.paymentHistory.perPage,
      })
        .then((data) => {
          this.paymentHistory.items = data.payments.map((t, idx) => {
            const first = idx === 0;
            // eslint-disable-next-line max-len
            t.tariff = tariffPlans.find((tariff) => tariff.id === +t.membership_id) || tariffPlans[0];
            if (first && t.status === 'canceled') {
              t.showCanceledButton = true;
            }
            if (first && t.status === 'pending') {
              t.showPendingButton = true;
            }
            return t;
          });
          this.paymentHistory.total = data.total;
        })
        .catch(this.errorMessage)
        .finally(() => (this.paymentHistory.loading = false));
    },
    showMore() {
      this.paymentHistory.perPage += 10;
      this.getPaymentHistory();
    },
    errorMessage(error) {
      const errMessage = error?.response?.data?.error;
      this.setToaster({
        type: 'toast-warning',
        toast: {
          title: this.$t('errorMessages.somethingWentWrong'),
          body: errMessage || this.$t('errorMessages.tryAgain'),
        },
      });
    },
  },
  computed: {
    ...mapGetters({ config: 'config/getAppConfig' }),
    ...mapGetters({ userTariff: 'auth/getUserTariff' }),
    isTariffFree() {
      return this.userTariff.id === 0;
    },
    tariffs() {
      return this.config.tariffs.filter((t) => t.id !== 0);
    },
    shortHistory() {
      return this.paymentHistory.items.slice(0, 1);
    },
    isDisableButtonAutoProlongation() {
      const isDefaultMembership = this.userTariff.membership?.id === 0;
      const isDefaultProlongation = this.userTariff.prolongation?.id === 0;
      const isAllDefault = isDefaultMembership && isDefaultProlongation;

      return isAllDefault || !this.isCardData;
    },
    canBeProlongated() {
      return !this.userTariff.isAutoProlongation && !!this.isCardData;
    },
    buttonVariant() {
      return this.canBeProlongated ? 'secondary' : 'primary';
    },
    buttonTitle() {
      return this.canBeProlongated
        ? this.$i18n.t('buttonLabels.enableAutoPayment')
        : this.$i18n.t('buttonLabels.cancelAutoPayment');
    },
    isCardData() {
      return (
        this.lastPaymentInfo?.card.length > 0
        && (this.lastPaymentInfo?.type === 'bank_card' || this.lastPaymentInfo?.type === 'yoo_money')
      );
    },
  },
};
</script>
