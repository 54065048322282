<template>
  <div
    class="payment-history__info-text"
    v-click-outside="closeFilterHint"
  >
    <InfoIcon @click="toggleFilterHint" />
    <div class="payment-history__text-helper">
      <div class="text-helper" :class="{ 'text-helper--active': filterHint }">
        <div class="text-helper__item">
          <div class="text-helper__title">
            <p>{{ header }}</p>
          </div>
          <div class="text-helper__subtitle">
            <p>
              {{ text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoIcon from '@/components/Icons/InfoIcon.vue';

export default {
  name: 'AsteriskHistoryPayment',

  components: {
    InfoIcon,
  },

  props: {
    item: {
      type: Object,
      require: true,
    },
  },

  data: () => ({
    filterHint: false,
  }),

  computed: {
    header() {
      return this.item.status === 'pending'
        ? this.$t('header.waitingPayment')
        : this.$t('header.paymentCancelation');
    },
    text() {
      if (this.item.status === 'pending') {
        return this.$t('supportText.ifPaymentNotDoneCanContinue');
      }
      return this.$t('supportText.canBeDuringAutopayIfMoneyNotEnough');
    },
  },

  methods: {
    toggleFilterHint() {
      this.filterHint = !this.filterHint;
    },
    closeFilterHint() {
      this.filterHint = false;
    },
  },
};
</script>
