<template>
  <!-- eslint-disable max-len -->
  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.694444 5.16667L1.94444 3.30556L0 2.69444L0.444444 1.30556L2.41667 2.11111L2.30556 0H4L3.88889 2.11111L5.86111 1.30556L6.27778 2.69444L4.36111 3.30556L5.61111 5.16667L4.38889 6L3.16667 4.02778L1.91667 6L0.694444 5.16667Z"
      fill="#999999"
    />
  </svg>
</template>

<script>
export default {
  name: 'Asterisk',
};
</script>
