<template>
  <div class="payment-history scroll">
    <div class="container">
      <div class="payment-history__title">
        <div>{{ $t('label.paymentHistory') }}</div>
        <BigCloseButton @click="$emit('update:showPaymentHistory', false)" />
      </div>

      <div class="line-separator"></div>
      <TablePaymentHistory :items="items"/>

      <div v-if="items.length !== total" class="payment-history__more" @click="$emit('showMore')">
        {{ $t('buttonLabels.showMore') }}
      </div>
    </div>
  </div>
</template>

<script>
import BigCloseButton from '@/components/Buttons/BigCloseButton.vue';
import TablePaymentHistory from '@/components/TablePaymentHistory.vue';

export default {
  name: 'PaymentHistory',

  components: {
    TablePaymentHistory,
    BigCloseButton,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    showPaymentHistory: {
      type: Boolean,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
};
</script>
