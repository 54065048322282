<template>
  <div class="tariffs-info lms-card">
    <p class="tariffs-info__title">{{ $t('label.tariffs') }}</p>
    <div class="tariffs-info__tariffs-grid">
      <template v-for="t in tariffs">
        <TariffSmallCard
          :id="t.id.toString()"
          :name="t.name"
          :price="t.price"
          :studentCount="t.studentsCount"
          :key="t.id"
        />
      </template>
    </div>
    <div v-if="showHintOfNextPayment && computedDate" class="tariffs-info__next-payment-info">
      <AsteriskIcon />
      <span>{{ $t('supportText.nextPayment') }} - {{ computedDate }}</span>
    </div>
  </div>
</template>

<script>
import TariffSmallCard from '@/components/Cards/TariffSmallCard.vue';
import AsteriskIcon from '@/components/Icons/AsteriskIcon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'TariffsInfoCompact',
  props: {
    tariffs: {
      type: Array,
      required: true,
    },
    isCardData: {
      type: Boolean,
      default: false,
    },
  },
  components: { TariffSmallCard, AsteriskIcon },
  computed: {
    ...mapGetters({ userTariff: 'auth/getUserTariff' }),
    computedDate() {
      const date = this.userTariff.expiresAt?.split(',')[0].split('.');
      if (!date) {
        return false;
      }

      const y = parseInt(date[2], 10);
      const m = parseInt(date[1], 10) - 1;
      const d = parseInt(date[0], 10);
      const result = new Date(y, m, d);

      result.setDate(result.getDate() - 1);
      return result.toLocaleDateString();
    },
    showHintOfNextPayment() {
      return this.userTariff.isAutoProlongation && !!this.isCardData;
    },
  },
};
</script>
