import axios from '@/http/index';

class SubscriptionService {
  makePayment = (payload) => axios.post('/payment/link', payload).then(({ data }) => data);

  changeSubscription = (payload) => axios.patch('teacher/subscription/', payload);

  abortSubscription = () => axios.delete('teacher/subscription');

  checkPaymentStatus = () => axios.get('/payment/status').then(({ data }) => data);

  getCardData = () => axios.get('/payment/card').then(({ data }) => data);

  forgotCardData = () => axios.patch('/payment/forget').then(({ data }) => data);

  getPaymentHistory = (query = { page: 1, perPage: 10 }) => axios
    .get(`/payment/history?page=${query.page}&per_page=${query.perPage}`)
    .then(({ data }) => data);

  enableSubscription = () => axios.patch('payment/auto_prolongation/on');

  disableSubscription = () => axios.patch('payment/auto_prolongation/off');
}

export default new SubscriptionService();
