<template>
  <div class="user-current-tariff-card lms-card">
    <div class="user-current-tariff-card__user-info">
      <p class="user-current-tariff-card__user-name">{{ $store.getters['auth/getFullName'] }}</p>
      <p class="user-current-tariff-card__user-email">{{ $store.getters['auth/getEmail'] }}</p>
    </div>

    <div class="user-current-tariff-card__tariff-info tariff-info">
      <p class="tariff-info__label">
        {{ $t('label.currentTariff') }}
        <span v-if="notFreeTariff">
          {{ $t('supportText.to') + ' ' + computedDate }}
        </span>
      </p>
      <p class="tariff-info__tariff-name">
        {{ translatedTariffName }}
        <span v-if="showNext">-</span>
        <span v-if="showNext"> {{ $t('label.next') }} {{ $t(userTariff.prolongation.name) }}</span>
      </p>
    </div>
    <div class="user-current-tariff-card__tariff-students-info">
      <p class="user-current-tariff-card__student-label">{{ $t('label.numberOfStudents') }}</p>
      <p class="user-current-tariff-card__student-count">
        <span>{{ userTariff.currentUserAmount }}</span>
        <i18n path="label.youCanAddMore" tag="span">{{ userLeftToAdd }}</i18n>
      </p>
    </div>

    <div class="user-current-tariff-card__tariff-payment-method">
      <div class="title">{{ $t('label.paymentMethod') }}</div>
      <div class="info">{{ lastPaymentTitle }}</div>
    </div>
    <div class="user-current-tariff-card__tariff-card-reset" v-if="isCardData">
      <span @click="forgotCard">
        {{ $t('label.forgetMyCard') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserCurrentTariffInfo',
  props: {
    showPaymentHistory: {
      type: Boolean,
      required: true,
    },
    forgotCard: {
      type: Function,
      required: true,
    },
    lastPaymentInfo: {
      type: Object,
      default: () => {},
    },
    isCardData: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters({ userTariff: 'auth/getUserTariff' }),
    translatedTariffName() {
      return this.$t(this.userTariff.name);
    },
    showNext() {
      const mId = this.userTariff.membership?.id;
      const pId = this.userTariff.prolongation?.id;
      const isAllDefault = mId === 0 && pId === 0;
      const isNotDefaultDifferentNext = mId !== pId && pId !== 0;
      const { isAutoProlongation } = this.userTariff;

      return isNotDefaultDifferentNext || (!isAllDefault && !isAutoProlongation);
    },
    userLeftToAdd() {
      if (this.userTariff.maxUsers - this.userTariff.currentUserAmount >= 0) {
        return this.userTariff.maxUsers - this.userTariff.currentUserAmount;
      }
      return 0;
    },
    computedDate() {
      return this.userTariff.expiresAt?.split(',')[0];
    },
    lastPaymentTitle() {
      if (this.lastPaymentInfo?.type === 'bank_card') {
        return `${this.$t('supportText.payByCard')} ****${this.lastPaymentInfo.card.split('*')[1]}`;
      }
      if (this.lastPaymentInfo?.type === 'b2b_sberbank') {
        return this.$t('supportText.payBySberBusiness');
      }
      return this.$i18n.t('label.notChosen');
    },
    notFreeTariff() {
      return this.userTariff.membership?.id !== 0;
    },
  },
};
</script>
