<template>
  <b-table
    class="lms-table program-statistic scroll"
    :items="items"
    :fields="fields()"
    primary-key="_id"
    borderless
    :tbody-transition-props="{ name: 'flip-list' }"
    stacked="sm"
    :no-local-sorting="true"
    :fixed="false"
    :no-provider-paging="true"
    :show-empty="true"
    :empty-text="$t('supportText.noPaymentBefore')"
    no-footer-sorting
    no-sort-reset
  >
    <template #cell(payment_method_title)="data">{{ paymentMethod(data.item) }} </template>
    <template #cell(tariff)="data">{{ $t(data.item.tariff.name) }} </template>
    <template #cell(created_at)="data"
      >{{ new Date(data.item.created_at).toLocaleDateString() }}
    </template>
    <template #cell(amount)="data">{{ parseInt(data.item.amount).toLocaleString() }} ₽</template>
    <template #cell(status)="data">
      <ProgressStatus class="status" iconPosition="left" :status="status(data.item)" />
      <AsteriskHistoryPayment v-if="data.index == 0 && showAsterisk(data.item)" :item="data.item" />
    </template>
    <template #cell(action)="data">
      <a
        v-if="data.item.showPendingButton"
        :href="data.item.confirmation_url"
        class="btn-secondary small d-flex justify-content-center"
        target="_blank"
        >{{ buttonLabel(data.item) }}</a
      >
      <AddButton
        v-else-if="data.item.showCanceledButton"
        :title="buttonLabel(data.item)"
        size="small"
        variant="secondary"
        @click="repeatPayment(data.item)"
      />
    </template>
  </b-table>
</template>

<script>
import AsteriskHistoryPayment from '@/components/Icons/AsteriskHistoryPayment.vue';
import ProgressStatus from '@/components/ProgressStatus.vue';
import AddButton from '@/components/Buttons/AddButton.vue';
import SubscriptionService from '@/services/subscription.service';
import { mapActions } from 'vuex';

export default {
  name: 'TablePaymentHistory',
  components: {
    AsteriskHistoryPayment,
    ProgressStatus,
    AddButton,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      perPage: 10,
      total: 0,
      loading: true,
    };
  },

  methods: {
    ...mapActions('toaster', ['setToaster']),
    buttonLabel(item) {
      return item.status === 'pending'
        ? this.$t('label.continuePayment')
        : this.$t('label.repeatPayment');
    },
    repeatPayment(item) {
      if (this.loadingGettingLink) {
        return;
      }
      const payload = {
        membership_id: +item.membership_id,
        return_url: `${window.location.origin}/post-paid`,
      };

      this.loadingGettingLink = true;

      SubscriptionService.makePayment(payload)
        .then(async ({ link }) => {
          const paymentLinkButton = this.$createElement('a', {
            attrs: {
              href: link,
              target: '_blank',
            },
            class: 'link',
          }, this.$t('buttonLabels.continuePayment'));

          this.$bvModal
            .msgBoxConfirm(this.$t('supportText.confirmPayment'), {
              size: 'sm',
              okVariant: 'primary',
              cancelVariant: 'secondary',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'lms-modal__footer link-footer border-top-0',
              centered: true,
              bodyClass: 'lms-modal__body',
              okTitle: paymentLinkButton,
              cancelTitle: this.$t('buttonLabels.cancel'),
            }).then((confirm) => {
              if (confirm) {
                this.setToaster({
                  type: 'toast-warning',
                  toast: {
                    title: this.$t('header.tariffChange'),
                    body: this.$t('supportText.tariffWillBeChangeAfterPayment'),
                  },
                  autoHideDelay: 30000,
                });
              }
            });
        })
        .catch((error) => {
          const verboseError = error?.response?.data?.error;
          if (verboseError === 'you have to ban some students') {
            return this.setToaster({
              type: 'toast-warning',
              toast: {
                title: this.$t('errorMessages.needToRemoveStudents'),
                body: this.$t('errorMessages.studentLimitWillBeOver'),
              },
            });
          }
          if (verboseError === 'there is a payment currently pending') {
            return this.setToaster({
              type: 'toast-warning',
              toast: {
                title: this.$t('errorMessages.previousPaymentInProcess'),
                body: this.$t('errorMessages.waitUntilPreviousPaymentDone'),
              },
            });
          }
          this.error = this.$t('errorMessages.somethingWentWrong');
        })
        .finally(() => (this.loadingGettingLink = false));
    },
    status(item) {
      if (item.status === 'succeeded') {
        return {
          value: 'done',
          label: 'label.succeded',
        };
      }
      if (item.status === 'pending') {
        return {
          value: 'doing',
          label: 'label.inProgress',
        };
      }
      if (item.status === 'canceled') {
        return {
          value: 'unavailable',
          label: 'label.canceled',
        };
      }
    },
    fields() {
      return [
        {
          key: 'payment_method_title',
          label: this.$t('label.paymentMethod'),
        },
        {
          key: 'tariff',
          label: this.$t('label.tariff'),
        },
        {
          key: 'created_at',
          label: this.$t('label.date'),
        },
        {
          key: 'amount',
          label: this.$t('label.sum'),
        },
        {
          key: 'status',
          label: this.$i18n.t('label.status'),
        },
        {
          key: 'action',
          label: this.$t('label.action'),
        },
      ];
    },
    paymentMethod(item) {
      const isPaidBySber = item?.payment_method_type === 'b2b_sberbank';
      const paymentTranslation = isPaidBySber
        ? this.$i18n.t('label.sberBuisnessOnline')
        : this.$i18n.t('label.bankCard');
      if (!item.payment_method_title) {
        return paymentTranslation;
      }

      const cardNumber = item.payment_method_title.split('*')[1];
      if (cardNumber) {
        return `${paymentTranslation} ****${cardNumber}`;
      }
      return paymentTranslation;
    },
    showAsterisk(item) {
      const notSuccessful = item?.status !== 'succeeded';
      return notSuccessful;
    },
  },
};
</script>
