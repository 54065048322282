<!-- eslint-disable max-len -->
<template>
  <div class="info-icon" @click="$emit('click')">
    <svg width="3" height="10" viewBox="0 0 3 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 3V9" stroke="#F2F2F2" stroke-width="0.8" stroke-linecap="round" />
      <path
        d="M2 1.5C2.27603 1.5 2.5 1.27596 2.5 0.999839C2.5 0.723378 2.27603 0.5 2 0.5C1.72395 0.5 1.5 0.723395 1.5 0.999839C1.5 1.27596 1.72395 1.5 2 1.5Z"
        fill="#F2F2F2"
      />
      <path d="M2 3H1" stroke="#F2F2F2" stroke-width="0.8" stroke-linecap="round" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'InfoIcon',
};
</script>
